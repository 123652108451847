import React from "react";
import "./index.css";
import OfferCardItem, { OfferCardItemProps } from "../OfferCardItem";

type Props = {
  cards?: OfferCardItemProps[];
};

const OfferCards = ({ cards }: Props) => {
  const defaultCards: OfferCardItemProps[] = [
    {
      icon: "development",
      link: {
        label: "Przejdź do oferty",
        href: "/oferta/przyrzady-produkcyjne",
      },
      title: "PRZYRZĄDY PRODUKCYJNE",
      body: (
        <>
          <ul>
            <li>Przyrządy kontrolno-pomiarowe</li>
            <li>Przyrządy montażowe</li>
            <li>Uchwyty i przyrządy obróbkowe</li>
            <li>Przyrządy spawalnicze</li>
            <li>Automatyzacja produkcji</li>
          </ul>
        </>
      ),
    },
    {
      icon: "management",
      link: { label: "Przejdź do oferty", href: "/oferta/uslugi-projektowe" },
      title: "USŁUGI PROJEKTOWE",
      body: (
        <>
          <ul>
            <li>Formy wtryskowe</li>
            <li>Wkrojniki</li>
            <li>Maszyny</li>
            <li>Symulacje</li>
          </ul>
        </>
      ),
    },
    {
      icon: "project-new",
      link: { label: "Przejdź do oferty", href: "/oferta/rozwoj-produktu" },
      title: "ROZWÓJ PRODUKTU",
      body: (
        <>
          <ul>
            <li>Projektowanie detali i złożeń</li>
            <li>Dobór materiałów</li>
            <li>Wsparcie wdrożenia</li>
          </ul>
        </>
      ),
    },
    {
      icon: "service",
      link: {
        label: "Przejdź do oferty",
        href: "/oferta/zarzadzanie-projektami",
      },
      title: "ZARZĄDZANIE PROJEKTAMI",
      body: <p>Od idei do pełnego wdrożenia</p>,
    },
  ];

  return (
    <div className="cmp-offer-cards">
      {(cards || defaultCards).map((props) => (
        <OfferCardItem key={props.title} {...props} />
      ))}
    </div>
  );
};

export default OfferCards;
