import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./apps/spa";

import "react-photo-view/dist/react-photo-view.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<App />);
