import React from "react";
import { renderRichTextChildItem, usePayloadOfferLiveData } from "../../services/payload";
import OfferTileItem from "../../components/OfferTileItem";

const OfferPreview = () => {
  const { data } = usePayloadOfferLiveData();
  return (
    <div style={{ maxWidth: "515px", marginInline: "auto", marginTop: "1rem" }}>
      <OfferTileItem title={data?.name || "Wypełnij pola by zobaczyć podgląd!"} href="#" img="/img/1.jpg">
        {data && renderRichTextChildItem(data.description)}
      </OfferTileItem>
    </div>
  );
};

export default OfferPreview;
