import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import MainPageView from "../views/MainPageView/inedx";

const MainPage = () => {
  return (
    <>
      <NavBar />
      <MainPageView />
      <Footer />
    </>
  );
};

export default MainPage;
