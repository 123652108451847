import React from "react";
import RightImageHeader from "../../components/RightImageHeader";
import Map from "../../components/Map";
import "./index.css";
import ContanctDetails from "../../components/ContanctDetails";
import GeneralCompanyDetail from "../../components/GeneralCompanyDetail";

type Props = {
  title: string;
  companyName: string;
  nip: string;
  location: string;
  locationLabel: string;
  phone: string;
  phoneLabel: string;
  email: string;
  emailLabel: string;
};

const ContactView = ({
  title,
  companyName,
  nip,
  location,
  locationLabel,
  phone,
  phoneLabel,
  email,
  emailLabel,
}: Props) => {
  return (
    <>
      <div className="cmp-container">
        <RightImageHeader text={title} />
        <GeneralCompanyDetail nip={nip} companyName={companyName} />
        <ContanctDetails
          location={location}
          locationLabel={locationLabel}
          phone={phone}
          phoneLabel={phoneLabel}
          email={email}
          emailLabel={emailLabel}
        />
        <Map />
      </div>
    </>
  );
};

export default ContactView;
