import React, { useState } from "react";
import "./index.css";
import PickerSection from "./PickerSection";
import ContentSection from "./ContentSection";

type Props = {
  title?: string;
  subtitle?: string;
};

const ImgCarousel = ({
  title = "Projektowanie i dostawa indywidulanych przyrządów produkcyjnych dopasowanych do twoich potrzeb",
  subtitle = "Indywidualne podejście doświadczenie rzetelność zaufanie",
}: Props) => {
  const [seleted, setSelected] = useState<string | undefined>();
  const contents = [
    {
      img: "/img/1.jpg",
      title: "Przyrządy produkcyjne",
      href: "/oferta/przyrzady-produkcyjne",
      body: (
        <>
          <ul>
            <li>Przyrządy kontrolno-pomiarowe</li>
            <li>Przyrządy montażowe</li>
            <li>Uchwyty i przyrządy obróbkowe</li>
            <li>Przyrządy spawalnicze</li>
            <li>Automatyzacja produkcji</li>
          </ul>
        </>
      ),
    },
    {
      img: "/img/2.jpg",
      title: "Usługi projektowe",
      href: "/oferta/uslugi-projektowe",
      body: (
        <>
          <ul>
            <li>Formy wtryskowe</li>
            <li>Wkrojniki</li>
            <li>Maszyny</li>
            <li>Symulacje</li>
          </ul>
        </>
      ),
    },
    {
      img: "/img/3.jpg",
      title: "Rozwój produktu",
      href: "/oferta/rozwoj-produktu",
      body: (
        <>
          <ul>
            <li>Projekty części i zespołów</li>
            <li>Analiza tolerancji</li>
            <li>Dobór materiałów i technologii wytworzenia</li>
            <li>Kalkulacja nakładów kosztów inwestycyjnych i wytworzenia</li>
            <li>Wdrożenie do produkcji</li>
          </ul>
        </>
      ),
    },
    {
      img: "/img/4.jpg",
      title: "Zarządzanie projektami",
      href: "/oferta/zarzadzanie-projektami",
      body: (
        <>
          <ul>
            <li>Od idei do pełnego wdrożenia</li>
            <li>Reprezentacja interesów klienta wobec dostawców</li>
            <li>Nadzór nad harmonogramem i budżetem</li>
          </ul>
        </>
      ),
    },
  ];

  const handleImageSelection = (img: string) => (img !== seleted ? setSelected(img) : setSelected(undefined));
  return (
    <div className="cmp-img-carousel">
      <div className="cmp-img-carousel__content">
        <ContentSection contents={contents} seleted={seleted} title={title} subtitle={subtitle} />
        <PickerSection contents={contents} seleted={seleted} handleImageSelection={handleImageSelection} />
      </div>
    </div>
  );
};

export default ImgCarousel;
