import React from "react";
import { resolvePageLayoutItem, usePayloadLiveData } from "../../services/payload";
import OfferPageTitle from "../../components/OfferPageTitle";
import SubPageNavigation from "../../components/SubPageNavigation";
import { useNavItems } from "../oferta/przyrzady-produkcyjne/useNavItems";

const LayoutPreview = () => {
  const { data } = usePayloadLiveData();
  const { navItems } = useNavItems();

  return (
    <div className="cmp-container">
      {data?.offer && <OfferPageTitle title="Rozwoj produktu" current="/oferta/rozwoj-produktu" />}
      {data?.offer && (
        <SubPageNavigation navIems={navItems} img="/img/1.jpg" currentSection="przyrzady-montazowe">
          <section id="preview" style={{ width: "100%" }}>
            {data && data.layout?.map(resolvePageLayoutItem)}
          </section>
        </SubPageNavigation>
      )}
      {data && !data.offer && data.layout?.map(resolvePageLayoutItem)}
    </div>
  );
};

export default LayoutPreview;
