import React from "react";
import "./index.css";

type Props = {
  nip?: string;
  companyName?: string;
};

const GeneralCompanyDetail = ({ nip, companyName }: Props) => {
  return (
    <div className="cmp-general-company-detail">
      <h3>{companyName}</h3>
      <h4>{nip}</h4>
    </div>
  );
};

export default GeneralCompanyDetail;
