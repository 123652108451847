import React from "react";
import ImgCarousel from "../../components/ImgCarousel";
import AboutCompany from "../../components/AboutCompany";
import OfferCards from "../../components/OfferCards";
import "./index.css";

const MainPageView = () => {
  return (
    <>
      <section id="carousel">
        <ImgCarousel />
      </section>
      <section id="about">
        <AboutCompany />
        <OfferCards />
      </section>
    </>
  );
};

export default MainPageView;
