import React from "react";
import "./index.css";

type Props = {
  display: boolean;
  title: string;
  subtitle: string;
};

const DefaultCard = ({ display, title, subtitle }: Props) => {
  const displayClass = display ? "cmp-img-carousel__card--display" : "";

  return (
    <div className={`cmp-img-carousel__card cmp-img-carousel__card--default ${displayClass}`}>
      <img src="/img/0.jpg" alt="" />
      <div className="cmp-img-carousel__card-default-title-container">
        <h2 className="cmp-img-carousel__card-default-title">{title}</h2>
        <h3 className="cmp-img-carousel__card-default-subtitle">{subtitle}</h3>
      </div>
    </div>
  );
};

export default DefaultCard;
