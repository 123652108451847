import { useLivePreview } from "@payloadcms/live-preview-react";

import Map from "../components/Map";
import CTAText from "../components/CTAText";
import TextTiles from "../components/TextTiles";
import GalleryCarousel from "../components/GalleryCarousel";
import SignatreStepList from "../components/SignatreStepList";
import RightImageHeader from "../components/RightImageHeader";
import OfferCards from "../components/OfferCards";
import { BACKEND_URL } from "./api";

export type CTABlockObject = {
  blockType: "CTABlock";
  text: string;
  id: string;
};

export type GalleryCarouselBlockObject = {
  blockType: "GalleryCarouselBlock";
  id: string;
  gallery: {
    relationTo: "gallery";
    value: { images: { id: string; image: { url: string; sizes: { thumbnail: { url: string } } } }[] };
  };
};

export type IconTilesBlockObject = {
  blockType: "IconTilesBlock";
  buttontext: string;
  firsttitle: string;
  firstdescription: RichTextChildItem[];
  firstlink: string;
  secondtitle: string;
  seconddescription: RichTextChildItem[];
  secondlink: string;
  thirdtitle: string;
  thirddescription: RichTextChildItem[];
  thirdlink: string;
  fourthtitle: string;
  fourthdescription: RichTextChildItem[];
  fourthlink: string;
  id: string;
};

export type MapBlockObject = {
  blockType: "MapBlock";
  openstreetmap: string;
  googlemap: string;
  id: string;
};

export type RichTextChildItemType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "text" | "ul" | "ol" | "indent";
// | "upload";

export type RichTextChildItem =
  | {
      children: RichTextChildItem[];
      type: RichTextChildItemType;
    }
  | {
      children: RichTextChildItem[];
      type: "upload";
      relationTo: "media";
      value: { url: string; sizes: { thumbnail: { url: string } } };
    }
  | {
      children: RichTextChildItem[];
      type: "link";
      doc: unknown;
      fields: unknown;
      linkType: "custom";
      newTab?: boolean;
      url: string;
    }
  | { text: string; code?: boolean; underline?: boolean; strikethrough?: boolean; italic?: boolean; bold?: boolean };

export type RichTextBlockObject = {
  blockType: "RichTextBlock";
  content: RichTextChildItem[];
  textalignment?: "left" | "center" | "right" | "justify";
  id: string;
};

export type StepperBlockObject = {
  blockType: "StepperBlock";
  steps: {
    signature: string;
    title: string;
    content: RichTextChildItem[];
    id: string;
  }[];
  id: string;
};

export type TilesBlockObject = {
  blockType: "TilesBlock";
  tiles: { text: string; id: string }[];
  id: string;
};

export type TitleBlockObject = {
  blockType: "TitleBlock";
  text: string;
  id: string;
};

export type TitleWithImageBlockObject = {
  blockType: "TitleWithImageBlock";
  text: string;
  image: { url: string; sizes: { thumbnail: { url: string } } };
  id: string;
};

export type PageLayoutItem =
  | CTABlockObject
  | GalleryCarouselBlockObject
  | IconTilesBlockObject
  | MapBlockObject
  | RichTextBlockObject
  | StepperBlockObject
  | TilesBlockObject
  | TitleBlockObject
  | TitleWithImageBlockObject;

export type PageObject = {
  urlslug: string;
  offer?: string;
  layout: PageLayoutItem[];
  updatedAt: string;
  createdAt: string;
  _status: "draft" | "published";
};

const resolveHtmlTag = (type: RichTextChildItemType | undefined): keyof React.ReactHTML => {
  if (!type) {
    return "p";
  }

  if (type === "indent" || type === "text") {
    return "p";
  }

  return type;
};

const renderRichTextChildItem = (items: RichTextChildItem[]) => {
  return items?.map((item, index) => {
    if ("text" in item) {
      const textDecoration =
        !item.underline && !item.strikethrough
          ? undefined
          : `${item.underline ? "underline" : ""} ${item.strikethrough ? "line-through" : ""}`.trim();
      const Tag = item.code ? "code" : "div";
      return (
        <Tag
          key={`${item.text?.toLowerCase()?.replaceAll(" ", "-")}-${index}`}
          style={{
            display: "inline",
            fontWeight: item.bold ? "bold" : "inherit",
            fontSize: "inherit",
            textDecoration: textDecoration,
            fontStyle: item.italic ? "italic" : "normal",
          }}
        >
          {item.text}
        </Tag>
      );
    }

    if (item.type === "upload") {
      return (
        <img
          key={`${item.value.url?.toLowerCase()?.replaceAll(" ", "-")}-${index}`}
          style={{ width: "100%" }}
          src={`${BACKEND_URL}${item.value.url}`}
          alt=""
        />
      );
    }

    if (item.type === "link") {
      return (
        <a
          key={`${item.url?.toLowerCase()?.replaceAll(" ", "-")}-${index}`}
          href={item.url}
          rel="noreferrer"
          target={item.newTab ? "_blank" : undefined}
        >
          {item.children.length > 0 ? renderRichTextChildItem(item.children) : ""}
        </a>
      );
    }

    const Tag = resolveHtmlTag(item.type);

    return (
      <Tag style={{ minHeight: "1rem" }}>{item.children.length > 0 ? renderRichTextChildItem(item.children) : ""}</Tag>
    );
  });
};

const usePayloadLiveData = () => {
  const { data, isLoading } = useLivePreview<PageObject | null>({
    initialData: null,
    serverURL: BACKEND_URL,
    depth: 5,
  });

  return { data, isLoading };
};

const usePayloadMainPageLiveData = () => {
  const { data, isLoading } = useLivePreview<{ title: string; subtitle: string; layout: PageLayoutItem[] } | null>({
    initialData: null,
    serverURL: BACKEND_URL,
    depth: 5,
  });

  return { data, isLoading };
};

type ContactLiveDataObject = {
  title: string;
  companyname: string;
  nip: string;
  emaillabel: string;
  email: string;
  phonelabel: string;
  phone: string;
  addresslabel: string;
  address: string;
  openstreetlink: string;
  googlemaplink: string;
};

const usePayloadContactLiveData = () => {
  const { data, isLoading } = useLivePreview<ContactLiveDataObject | null>({
    initialData: null,
    serverURL: BACKEND_URL,
    depth: 5,
  });

  return { data, isLoading };
};

type OfferLiveDataObject = {
  name: string;
  description: RichTextChildItem[];
};

const usePayloadOfferLiveData = () => {
  const { data, isLoading } = useLivePreview<OfferLiveDataObject | null>({
    initialData: null,
    serverURL: BACKEND_URL,
    depth: 5,
  });

  return { data, isLoading };
};

const resolvePageLayoutItem = (item: PageLayoutItem) => {
  if (item.blockType === "CTABlock") {
    return <CTAText key={item.id} text={item.text} />;
  }
  if (item.blockType === "GalleryCarouselBlock") {
    return (
      <GalleryCarousel
        key={item.id}
        content={item.gallery.value.images.map((img) => ({
          src: `${BACKEND_URL}${img.image.url}`,
          thumb: `${BACKEND_URL}${img?.image?.sizes?.thumbnail?.url}`,
        }))}
      />
    );
  }
  if (item.blockType === "IconTilesBlock") {
    return (
      <OfferCards
        key={item.id}
        cards={[
          {
            icon: "development",
            link: {
              label: item.buttontext,
              href: item.firstlink,
            },
            title: item.firsttitle,
            body: renderRichTextChildItem(item.firstdescription),
          },
          {
            icon: "development",
            link: {
              label: item.buttontext,
              href: item.secondlink,
            },
            title: item.secondtitle,
            body: renderRichTextChildItem(item.seconddescription),
          },
          {
            icon: "development",
            link: {
              label: item.buttontext,
              href: item.thirdlink,
            },
            title: item.thirdtitle,
            body: renderRichTextChildItem(item.thirddescription),
          },
          {
            icon: "development",
            link: {
              label: item.buttontext,
              href: item.fourthlink,
            },
            title: item.fourthtitle,
            body: renderRichTextChildItem(item.fourthdescription),
          },
        ]}
      />
    );
  }
  if (item.blockType === "MapBlock") {
    return <Map key={item.id} />;
  }
  if (item.blockType === "RichTextBlock") {
    return (
      <div key={item.id} style={{ textAlign: item.textalignment }}>
        {renderRichTextChildItem(item.content)}
      </div>
    );
  }
  if (item.blockType === "StepperBlock") {
    return (
      <SignatreStepList
        key={item.id}
        steps={item.steps?.map((step) => ({
          signature: step.signature,
          title: step.title,
          body: <>{renderRichTextChildItem(step.content)}</>,
        }))}
      />
    );
  }
  if (item.blockType === "TilesBlock") {
    return <TextTiles key={item.id} texts={(item.tiles || [])?.map((tile) => tile.text)} />;
  }
  if (item.blockType === "TitleBlock") {
    return <h2 key={item.id}>{item.text}</h2>;
  }
  if (item.blockType === "TitleWithImageBlock") {
    return <RightImageHeader key={item.id} text={item.text} img={`${BACKEND_URL}${item.image.url}`} />;
  }
  return <></>;
};

export {
  usePayloadLiveData,
  usePayloadContactLiveData,
  usePayloadMainPageLiveData,
  usePayloadOfferLiveData,
  renderRichTextChildItem,
  resolvePageLayoutItem,
};
