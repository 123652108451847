import React from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import { useSearchParams } from "react-router-dom";
import LayoutPreview from "./LayoutPreview";
import ContactPreview from "./ContactPreview";
import MainPagePreview from "./MainPagePreview";
import OfferPreview from "./OfferPreview";

const Preview = () => {
  const [params] = useSearchParams();

  return (
    <>
      <NavBar />
      {params.get("global") === "contact" && <ContactPreview />}
      {params.get("global") === "mainpage" && <MainPagePreview />}
      {params.get("collection") === "pages" && <LayoutPreview />}
      {params.get("collection") === "offer" && <OfferPreview />}
      <Footer />
    </>
  );
};

export default Preview;
