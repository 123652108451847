import React from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import "./index.css";
import ContactView from "../../views/ContactView";

const ContactPage = () => {
  const content = {
    title: "Kontakt",
    companyName: "DI-TECH Dariusz Izydorczyk",
    nip: "923-131-28-56",
    location: "64-200 Wolsztyn, źródlana 7",
    locationLabel: "Adres",
    phone: "606 710 695",
    phoneLabel: "Telefon",
    email: "biuro@di-tech.pl",
    emailLabel: "Email",
  };

  return (
    <>
      <NavBar />
      <ContactView {...content} />
      <Footer />
    </>
  );
};

export default ContactPage;
