import React from "react";
import { ReactComponent as EmailLogo } from "./email-svgrepo-com.svg";
// import { ReactComponent as NIPLogo } from "./id-card-svgrepo-com.svg";
import { ReactComponent as LocationLogo } from "./location-pin-alt-svgrepo-com.svg";
import { ReactComponent as PhoneLogo } from "./phone-rounded-svgrepo-com.svg";
import "./index.css";

type Props = {
  location: string;
  locationLabel: string;
  phone: string;
  phoneLabel: string;
  email: string;
  emailLabel: string;
};

const ContanctDetails = ({ location, locationLabel, phone, phoneLabel, email, emailLabel }: Props) => {
  // const location = "64-200 Wolsztyn, źródlana 7";
  // const phone = "606 710 695";
  // const email = "biuro@di-tech.pl";

  return (
    <div className="cmp-contact-details">
      <div className="cmp-contact-details__detail">
        <EmailLogo />
        <h2>{emailLabel}</h2>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
      <div className="cmp-contact-details__detail">
        <PhoneLogo />
        <h2>{phoneLabel}</h2>
        <a href={`phone:${phone}`}>{phone}</a>
      </div>
      <div className="cmp-contact-details__detail">
        <LocationLogo />
        <h2>{locationLabel}</h2>
        <span>{location}</span>
      </div>
      {/* <div className="cmp-contact-details__detail cmp-contact-details__nip">
        <NIPLogo />
        <h2>NIP</h2>
        <span>{nip}</span>
      </div> */}
    </div>
  );
};

export default ContanctDetails;
