/* eslint-disable react/jsx-pascal-case */

import React from "react";

import { Navigate, RouteObject } from "react-router-dom";

import TypoTestPage from "./pages/test";

import StronaGlowna from "./pages";

import Kontakt from "./pages/kontakt";

import Oferta from "./pages/oferta";

import Oferta_WsparcieProdukcji_AutomatyzacjaProdukcji from "./pages/oferta/przyrzady-produkcyjne/automatyzacja-produkcji";
import Oferta_WsparcieProdukcji_PrzyrzadyMontazowe from "./pages/oferta/przyrzady-produkcyjne/przyrzady-montazowe";
import Oferta_WsparcieProdukcji_PrzyrzadySpawalnicze from "./pages/oferta/przyrzady-produkcyjne/przyrzady-spawalnicze";
import Oferta_WsparcieProdukcji_PrzyrzadyKontrolnoPomiarowe from "./pages/oferta/przyrzady-produkcyjne/przyrzady-kontrolno-pomiarowe";
import Oferta_WsparcieProdukcji_UchwytyPrzyrzadyObrobkowe from "./pages/oferta/przyrzady-produkcyjne/uchwyty-i-przyrzady-obrobkowe";

import Oferta_UslugiProjekty_FormyWtryskowe from "./pages/oferta/uslugi-projektowe/formy-wtryskowe";
import Oferta_UslugiProjekty_Maszyny from "./pages/oferta/uslugi-projektowe/maszyny";
import Oferta_UslugiProjekty_Symulacje from "./pages/oferta/uslugi-projektowe/symulacje";
import Oferta_UslugiProjekty_Wkrojniki from "./pages/oferta/uslugi-projektowe/wkrojniki";

import Oferta_RozwojProduktu from "./pages/oferta/rozwoj-produktu";
import Oferta_ZarzadzanieProjektami from "./pages/oferta/zarzadzanie-projektami";

import JakDzialamy from "./pages/jak-dzialamy";

import Galeria from "./pages/galeria";
import Preview from "./pages/preview";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <StronaGlowna />,
  },
  {
    path: "/kontakt",
    element: <Kontakt />,
  },
  {
    path: "/oferta",
    element: <Oferta />,
  },
  {
    path: "/oferta/przyrzady-produkcyjne",
    element: <Navigate to="/oferta/przyrzady-produkcyjne/przyrzady-kontrolno-pomiarowe" />,
  },
  {
    path: "/oferta/przyrzady-produkcyjne/automatyzacja-produkcji",
    element: <Oferta_WsparcieProdukcji_AutomatyzacjaProdukcji />,
  },
  {
    path: "/oferta/przyrzady-produkcyjne/przyrzady-montazowe",
    element: <Oferta_WsparcieProdukcji_PrzyrzadyMontazowe />,
  },
  {
    path: "/oferta/przyrzady-produkcyjne/przyrzady-spawalnicze",
    element: <Oferta_WsparcieProdukcji_PrzyrzadySpawalnicze />,
  },
  {
    path: "/oferta/przyrzady-produkcyjne/przyrzady-kontrolno-pomiarowe",
    element: <Oferta_WsparcieProdukcji_PrzyrzadyKontrolnoPomiarowe />,
  },
  {
    path: "/oferta/przyrzady-produkcyjne/uchwyty-i-przyrzady-obrobkowe",
    element: <Oferta_WsparcieProdukcji_UchwytyPrzyrzadyObrobkowe />,
  },
  {
    path: "/oferta/uslugi-projektowe",
    element: <Navigate to="/oferta/uslugi-projektowe/formy-wtryskowe" />,
  },
  {
    path: "/oferta/uslugi-projektowe/formy-wtryskowe",
    element: <Oferta_UslugiProjekty_FormyWtryskowe />,
  },
  {
    path: "/oferta/uslugi-projektowe/maszyny",
    element: <Oferta_UslugiProjekty_Maszyny />,
  },
  {
    path: "/oferta/uslugi-projektowe/symulacje",
    element: <Oferta_UslugiProjekty_Symulacje />,
  },
  {
    path: "/oferta/uslugi-projektowe/wkrojniki",
    element: <Oferta_UslugiProjekty_Wkrojniki />,
  },
  {
    path: "/oferta/rozwoj-produktu",
    element: <Oferta_RozwojProduktu />,
  },
  {
    path: "/oferta/zarzadzanie-projektami",
    element: <Oferta_ZarzadzanieProjektami />,
  },
  {
    path: "/jak-dzialamy",
    element: <JakDzialamy />,
  },
  {
    path: "/galeria",
    element: <Galeria />,
  },
  {
    path: "/test",
    element: <TypoTestPage />,
  },
  {
    path: "/preview",
    element: <Preview />
  }
];

export const existingRoutes = routes.map((route) => route.path).filter((route) => route);
