import React from "react";
import { OFFER_TILES } from "../../store";
import "./index.css";

type Props = {
  title: string;
  current?:
    | "/oferta/przyrzady-produkcyjne"
    | "/oferta/uslugi-projektowe"
    | "/oferta/rozwoj-produktu"
    | "/oferta/zarzadzanie-projektami";
};

const OfferPageTitle = ({ title, current }: Props) => {
  const createItemInlineStyle = (img: string, href: string): React.CSSProperties => {
    const color = href === current ? "44, 74, 167" : "0, 0, 0";
    return {
      background: `linear-gradient(0deg, rgba(${color}, 0.7), rgba(${color}, 0.7)), url(${img})`,
    };
  };

  return (
    <div className="cmp-offer-page-title">
      <h1>{title}</h1>
      <div className="cmp-offer-page-title__links-wraper">
        <div className="cmp-offer-page-title__links">
          {OFFER_TILES.map((tile, index) => (
            <a
              className="cmp-offer-page-title__link-item"
              key={`offer-page-link-${tile.href?.toLowerCase()?.replaceAll(" ", "-")}-${index}`}
              href={tile.href}
              style={createItemInlineStyle(tile.img, tile.href)}
            >
              {tile.title}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OfferPageTitle;
