import React from "react";
import googleMapsLogo from "./google-maps-logo.webp";
import "./index.css";

const Map = ({
  openStreetMaps = "https://www.openstreetmap.org/export/embed.html?bbox=16.12049996852875%2C52.11889851322004%2C16.127581000328068%2C52.12199465872156&amp;layer=mapnik&marker=52.120357,16.124041",
  googleMaps = "https://maps.app.goo.gl/qAPhzSGBFKimDiuK6",
}: {
  openStreetMaps?: string;
  googleMaps?: string;
}) => {
  return (
    <div className="cmp-map">
      <iframe title="adres" src={openStreetMaps}></iframe>
      <a href={googleMaps} target="_blank" rel="noreferrer">
        <img src={googleMapsLogo} alt="Google Maps Logo" />
        Otwórz na mapach Google
      </a>
    </div>
  );
};

export default Map;
