import React from "react";
import { usePayloadContactLiveData } from "../../services/payload";
import ContactView from "../../views/ContactView";

const ContactPreview = () => {
  const { data } = usePayloadContactLiveData();

  if (!data) {
    return <p>Wypełnij pole by zobaczyć podgląd</p>;
  }

  return (
    <ContactView
      title={data.title}
      companyName={data.companyname}
      nip={data.nip}
      location={data.address}
      locationLabel={data.addresslabel}
      phone={data.phone}
      phoneLabel={data.phonelabel}
      email={data.email}
      emailLabel={data.emaillabel}
    />
  );
};

export default ContactPreview;
