import DesktopList from "./DesktopList";
import MobileList from "./MobileList";
import "./index.css";

type Props = {
  steps?: {
    signature: string;
    title: string;
    body: JSX.Element;
  }[];
};

const SignatreStepList = ({ steps }: Props) => {
  const content = steps || [
    {
      signature: "M1",
      title: "Analiza wymagań - określenie specyfikacji technicznej",
      body: (
        <ul>
          <li>Sprawdzenie rysunków i modeli</li>
          <li>Spisanie oczekiwań i wymagań klienta</li>
          <li>Wyjaśnienie wszystkich wątpliwości</li>
          <li>Wizja lokalna</li>
        </ul>
      ),
    },
    {
      signature: "M2",
      title: "Określenie zakresu zlecenia/projektu",
      body: (
        <ul>
          <li>Przygotowanie wstępnej koncepcji</li>
          <li>Kalkulacja kosztów z uwzględnieniem możliwych rozwiązań wariantowych</li>
          <li>Przygotowanie oferty cenowej</li>
          <li>Propozycja harmonogramu &#40;uwzględniającego np. terminy dostępności części do prób itp.&#41;</li>
        </ul>
      ),
    },
    {
      signature: "M3",
      title: "AKCEPTACJA OFERT",
      body: (
        <ul>
          <li>Zamówienie klienta</li>
        </ul>
      ),
    },
    {
      signature: "M4",
      title: "Przygotowanie dokumentacji",
      body: (
        <ul>
          <li>Projekt szczegółowy</li>
          <li>Pozyskanie ofert na elementy składowe &#40;systemy G-K, elementy zakupowe itp.&#41;</li>
          <li>Aktualizacja wyceny projektu oraz terminów dostawy vs. harmonogram projektu</li>
          <li>Przygotowanie i testu prototypu &#40;jeśli wymagane&#41;</li>
        </ul>
      ),
    },
    {
      signature: "M5",
      title: "Zatwierdzenie projektu z klientem",
      body: (
        <ul>
          <li>Zatwierdzenie koncepcji przez klienta</li>
          <li>Wprowadzenie korekt i uwag klienta</li>
          <li>Aktualizacja harmonogramu i budżetu projektu &#40;w przypadku zmian w stosunku do założeń oferty&#41;</li>
        </ul>
      ),
    },
    {
      signature: "M6",
      title: "Faza realizacji",
      body: (
        <ul>
          <li>Wykonanie części specjalnych oraz zakup elementów handlowych</li>
          <li>Kontrola jakości i montaż dostarczonych części</li>
          <li>Testy</li>
          <li>Kontrola jakości, w tym raport pomiarowy &#40;jeśli wymagany&#41;</li>
        </ul>
      ),
    },
    {
      signature: "M7",
      title: "Dostawa i odbiór końcowy przez klienta",
      body: (
        <>
          <ul>
            <li>Dokumentacja konstrukcyjna</li>
            <li>Dokumentacja techniczno-ruchowa</li>
            <li>Dokumentacja CE &#40;jeśli wymagana&#41;</li>
            <li>Raport pomiarowy &#40;jeśli wymagany&#41;</li>
            <li>
              Uruchomienie i szkolenie pracowników &#40;w zależności od ustaleń na etapie oferty i zamówienia&#41;
            </li>
          </ul>
          <p>Każdorazowo warunki dostawy i odbioru oraz dokumentacji są ustalane na samym początku projektu.</p>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="cmp-signature-step-list--desktop">
        <DesktopList content={content} />
      </div>
      <div className="cmp-signature-step-list--mobile">
        <MobileList content={content} />
      </div>
    </>
  );
};

export default SignatreStepList;
