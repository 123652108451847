import React from "react";
import ImgCarousel from "../../components/ImgCarousel";
import { resolvePageLayoutItem, usePayloadMainPageLiveData } from "../../services/payload";

const MainPagePreview = () => {
  const { data } = usePayloadMainPageLiveData();
  return (
    <>
      <section id="carousel">
        <ImgCarousel title={data?.title} subtitle={data?.subtitle} />
      </section>
      <section id="about">
        <div className="cmp-container">{data && data.layout?.map(resolvePageLayoutItem)}</div>
      </section>
    </>
  );
};

export default MainPagePreview;
