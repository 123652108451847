import React from "react";
import "./index.css";

type RightImageHeaderProps = {
  text: string;
  img?: string;
};

const RightImageHeader = ({ text, img = "/img/4.jpg" }: RightImageHeaderProps) => {
  return (
    <header className="cmp-right-image-header">
      <h1>{text}</h1>
      <img alt="" src={img} />
    </header>
  );
};

export default RightImageHeader;
